<template>
    <div>
      <el-container>
        <el-main>
          <head-layout
            head-title="领用记录信息"
            :head-btn-options="headTopBtnOptions"
            @head-cancel="handleHeadGoback"
            @head-save="headSave(1)"
            @head-submit="headSave(2)"
          />
          <avue-form :option="formOptions" ref="formLayout" v-model="dataForm" style="padding:10px;" >
            <template slot="issueCode">
                <el-input v-model="dataForm.issueCode" maxlength="100" style="width: calc(100% - 120px)" :disabled="type == 'view'"></el-input>
                <el-switch v-model="generate" active-text="自动生成" @change="handleSwidth" :disabled="type == 'view'"></el-switch>
            </template>
            <template slot="userName">
              <el-input v-model="dataForm.userName" ref="userName" @focus="changeUser" :disabled="type == 'view'"></el-input>
            </template>
          </avue-form>

        </el-main>
      </el-container>
      <template v-if="type!=='add'">
        <el-container>
            <el-main>
                <head-layout
                    head-title="物资清单"
                    :head-btn-options="inventoryBtnOptions"
                    @inventory-add="handleDetailAdd"
                    @inventory-remove="rowRemove"
                /><grid-layout
                    ref="gridLayOut"
                    :grid-row-btn="gridRowBtn"
                    :table-options="tableOptions"
                    :table-data="tableData"
                    :data-total="page.total"
                    :page="page"
                    :table-loading="tableLoading"
                    :editType="inline"
                    @page-current-change="handleCurrentChange"
                    @page-size-change="handleSizeChange"
                    @page-refresh-change="onLoad"
                    @row-edit="rowEdit"
                    @row-remove="rowRemove"
                    @gird-handle-select-click="selectionChange"
                >
            </grid-layout>
            </el-main>
        </el-container>
      </template>
      <el-dialog title="人员选择" :visible.sync="dialogVisible" width="80%" top="8vh">
        <UserDetpDialog ref="UserDetpDialog" @select-data="selectData" />
      </el-dialog>
      <el-dialog title="物资清单" :visible.sync="detailDialog" width="60%" :before-close="handleCancel">
        <avue-form :option="detailOptions" ref="detailLayout" v-model="detailForm" style="padding:10px;" @submit="handleSubmit">
          <template slot="quantity">
            <el-input-number
              v-model="detailForm.quantity"
              controls-position="right"
              :min="0"
              style="width: 100%"
              ></el-input-number>
          </template>
        </avue-form>
        <template slot="footer">
            <el-button size="small" @click="handleCancel()" v-loading="dialogLoading" > {{ $t(`cip.cmn.btn.celBtn`) }} </el-button>
            <el-button size="small" type="primary" @click="handleSave" v-loading="dialogLoading" > {{ $t(`cip.cmn.btn.defBtn`) }} </el-button>
        </template>
      </el-dialog>
    </div>
  </template>

  <script>
  import FormLayout from "@/views/components/layout/form-layout";
  import HeadLayout from "@/views/components/layout/head-layout.vue";
  import GridLayout from "@/views/components/layout/grid-layout.vue";
  import { getPageCode } from '@/api/system/serialNumber'
  import { mapGetters } from "vuex";
  import UserDetpDialog from '@/views/components/UserDeptDialog/UserDetpDialog'
  import { ohissueSubmit, ohissueDetail, ohissueDetailPage, ohissuedetailSubmit, ohissuedetailRemove } from '@/api/healthManage/appliance'
  import {dateFormat} from "@/util/date";

  export default {
    components: {
      FormLayout,
      HeadLayout,
      GridLayout,
      UserDetpDialog,
    },

    data() {
      return {
        nodeData: {},
        type: "",
        dataForm: {},
        generate: true,
        dialogVisible: false,
        tableData: [],
        tableLoading: false,
        page: {
            pageSize: 20,
            currentPage: 1,
            total: 0
        },
        detailDialog: false,
        detailForm: {},
        dialogLoading: false,
        selectionList: [],
      };
    },

    created() {
      this.nodeData = this.$route.query.row ? JSON.parse(this.$route.query.row) : {};
      this.type = this.$route.query.type;
      if (this.nodeData.id){
        this.getDetail(this.nodeData.id)
      } else {
        this.dataForm.orgId = this.userInfo.dept_id
        this.dataForm.orgCode = this.userInfo.deptCode ? this.userInfo.deptCode : ''
        this.handleSwidth(this.generate)
        this.dataForm.issueDat = dateFormat(new Date(), "yyyy-MM-dd")
        if(this.nodeData.userId) {
          this.dataForm.userId = this.nodeData.userId
          this.dataForm.userName = this.nodeData.userName
          this.dataForm.deptName = this.nodeData.deptName
        }
      }
    },

    mounted () {
      if(this.type == 'view'){
        this.$refs.formLayout.allDisabled = true
      }
    },

    computed: {
      ...mapGetters(["permission", "colorName", "userInfo"]),
      permissionList() {
        return {
          addBtn: this.vaildData(this.permission.eduquestions_add, true),
          viewBtn: this.vaildData(this.permission.eduquestions_view, true),
          editBtn: this.vaildData(this.permission.eduquestions_edit, true),
          delBtn: this.vaildData(this.permission.eduquestions_delete, true),
        };
      },
      formOptions() {
        return {
          size: "small",
          submitBtn: false,
          emptyBtn: false,
          labelWidth: 130,
          column: [
            {
              label: "记录编号",
              prop: "issueCode",
              placeholder: "请填写",
              span: 8,
              formslot: true,
              rules: [
                {
                  required: true,
                  message: "请填写",
                  trigger: ["blur", "change"],
                },
                {
                  max: 120,
                  message: "文件名称不能超过120个字符",
                  trigger: "blur",
                },
              ],
            },
            {
              label: "领用人",
              prop: "userName",
              placeholder: "请填写",
              span: 8,
              formslot:true,
              rules: [
                {
                  required: true,
                  message: "请填写",
                  trigger: "change",
                },
                {
                  max: 120,
                  message: "文件编号不能超过120个字符",
                  trigger: "change",
                },
              ],
            },
            {
              label: "领用日期",
              prop: "issueDat",
              placeholder: "请选择",
              span: 8,
              type: "date",
              format: "yyyy-MM-dd", // 设置日期格式
              valueFormat: "yyyy-MM-dd", // 设置数据库中存储的日期格式
              rules: [
                {
                  required: true,
                  message: "请填写",
                  trigger: "change",
                },
              ],
            },
            {
              label: "备注",
              prop: "remark",
              placeholder: "请填写",
              type: 'textarea',
              span: 24,
              maxlength:255,
              showWordLimit: true,
            },
          ],
        };
      },
      headTopBtnOptions() {
        let buttonBtn = [];
        if (this.type != "view") {
          buttonBtn.push({
            label: this.$t(`cip.cmn.btn.addBtn`),
            emit: "head-save",
            type: "button",
            icon: "",
            btnOptType: "save",
          });
          buttonBtn.push({
            label: '提交',
            emit: "head-submit",
            type: "button",
            icon: "",
            btnOptType: "submit",
          });
        }
        buttonBtn.push({
          label: this.$t("cip.cmn.btn.celBtn"),
          emit: "head-cancel",
          type: "button",
          icon: "",
        });
        return buttonBtn;
      },

      inventoryBtnOptions () {
        let btnList = [
          {
            label: this.$t(`cip.cmn.btn.addBtn`),
            emit: "inventory-add",
            type: "button",
            icon: "",
            btnOptType: "add",
          },
          {
            label: '移除',
            emit: "inventory-remove",
            type: "button",
            icon: "",
            btnOptType: "remove",
          },
        ]
        return this.type == 'view' ? [] : btnList
      },
      gridRowBtn () {
        return [
          {
            label: this.$t(`cip.cmn.btn.editBtn`),
            emit: "row-edit",
            type: "button",
            icon: "",
            btnOptType: "edit",
          },
          {
            label: '移除',
            emit: "row-remove",
            type: "button",
            icon: "",
            btnOptType: "remove",
          },
        ]
      },

      tableOptions () {
          return{
              customRowAddBtn: false,
              menuWidth: 260,
              menu: this.type == 'view'? false: true,
              selection: this.type == 'view'? false: true,
              linklable: '',
              column: [
                  {
                      label: "物资名称",
                      prop: "itemName",
                      align: "center",
                      overHidden: true,
                  },
                  {
                      label: "领用数量",
                      prop: "quantity",
                      align: "center",
                      overHidden: true,
                  },
              ],
          }
      },

      detailOptions() {
        return {
          size: "small",
          submitBtn: false,
          emptyBtn: false,
          labelWidth: 130,
          column: [
            {
              label: "物资名称",
              prop: "itemName",
              placeholder: "请填写",
              span: 12,
              maxlength: 255,
              rules: [
                {
                  required: true,
                  message: "请填写",
                  trigger: ["blur", "change"],
                },
              ],
            },
            {
              label: "领用数量",
              prop: "quantity",
              placeholder: "请填写",
              span: 12,
              formslot: true,
              rules: [
                {
                  required: true,
                  message: "请填写",
                  trigger: ["blur", "change"],
                },
              ],
            },
          ],
        };
      },
    },

    methods: {
      getDetail(id) {
        ohissueDetail({ id }).then((res) => {
          const data = res.data.data;
          this.dataForm = data;
          this.onLoad(this.page)
        });
      },

      handleHeadGoback() {
        this.$router.$avueRouter.closeTag();
        this.$router.back();
      },
      // 保存
      headSave(val) {
        this.$refs.formLayout.$refs.form.validate((valid) => {
          if (valid) {
            if(val ==2 && !this.dataForm.id){
              this.$message
            }
            if(val == 2) {
              this.dataForm.issueStatus = 'finished'
            }
            ohissueSubmit({ ...this.dataForm }).then((res) => {
              this.$refs.formLayout.$refs.form.allDisabled = false;
                this.$router.$avueRouter.closeTag();
                this.$loading().close();
                if(val == 1 && !this.nodeData.id){
                  this.$router.replace({
                    path: "/business/healthManage/appliance/form",
                    query: {
                      row: JSON.stringify({ id: res.data.data.id }),
                      type: "edit",
                    },
                  });
                }
                if (val == 2) {
                  this.handleHeadGoback();
                }
            });
          }
        });
      },

      handleSwidth (val) {
        if(val) {
            getPageCode({ruleCode: 'oh_issue_code'}).then(res=>{
                this.dataForm.issueCode = res.data.data
            })
        }
      },

      // 人员选择
      changeUser () {
        this.dialogVisible = true
        this.$refs.userName.blur()
      },

      selectData(row,deptId,deptName){
            this.dataForm.userId = row.id
            this.dataForm.userName = row.realName
            this.dialogVisible = false
        },

        /**
         * @des 物资清单
         */

         onLoad(page) {
          this.tableLoading = true;
            this.page = page;
            ohissueDetailPage(page.currentPage, page.pageSize, {issueId: this.dataForm.id}).then(res => {
                const data = res.data.data;
                this.$refs.gridLayOut.page.total = data.total;
                this.tableData = data.records;
                this.tableLoading = false;
            });
        },
        //页数改变
        handleCurrentChange(e) {
            this.page.currentPage = e.currentPage
            this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm)
        },
        //size改变
        handleSizeChange(e) {
            this.page.pageSize = e.pageSize
            this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm)
        },
         handleDetailAdd () {
          this.detailDialog = true
         },

         rowEdit (row) {
          this.detailForm = row
          this.detailDialog = true
         },

         selectionChange (list) {
            this.selectionList = list
          },

         rowRemove (row,index) {
            let ids = ''
            if(row) {
                ids = row.id
            } else {
                ids = this.selectionList.map(val=>val.id).toString()
                if(!ids){
                    this.$message.warning('请选择操作数据')
                }
            }
            if(ids){
                this.$confirm("确认删除当前数据吗?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {
                  ohissuedetailRemove(ids).then((res) => {
                        if (res.data.code == 200) {
                            this.$message.success("删除成功")
                            this.onLoad(this.page);
                        }
                    });
                }).catch((error) => {
                        this.$message({
                            message: "取消删除",
                            type: "info",
                        });
                    });
            }
        },

         handleCancel () {
            this.$refs.detailLayout.resetFields()
            this.dialogLoading = false
            this.detailDialog = false
        },

        handleSave () {
            this.$refs.detailLayout.submit();
        },

        handleSubmit (data,done) {
            this.$refs.detailLayout.validate(valid => {
                if(valid){
                    // this.form.factoryDate = this.form.factoryDate ? new Date(this.form.factoryDate) : ''
                    this.dialogLoading = true
                    if(!this.detailForm.id){
                      this.detailForm.issueId = this.dataForm.id
                      this.detailForm.orgId = this.userInfo.dept_id
                      this.detailForm.orgCode = this.userInfo.deptCode ? this.userInfo.deptCode : ''
                    }
                    this.$loading().close();
                    ohissuedetailSubmit(this.detailForm).then(res=>{
                        this.$message.success("操作成功")
                        this.handleCancel()
                        this.onLoad(this.page)
                        done()
                    }).catch(()=>{
                        done()
                        this.dialogLoading = false
                    })
                }
            })
        },


    }

  };
  </script>

  <style lang="scss" scoped>
    // ::v-deep .switch-inputinput{
    //   width: calc(100% - 120px);
    // }
    .el-switch{
        margin-left: 10px;
    }
  </style>
